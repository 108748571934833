module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":900,"withWebp":true,"tracedSVG":false,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[".md"]}},{"resolve":"gatsby-local-autolink-headers","options":{"isIconAfterHeader":true}},{"resolve":"gatsby-remark-vscode","options":{"theme":"Dark+ (default dark)","wrapperClassName":"","injectStyles":true,"extensions":[],"extensionDataDirectory":"/opt/render/project/src/extensions","languageAliases":{},"logLevel":"warn"}}],"remarkPlugins":[null,null],"rehypePlugins":[null,null],"extensions":[".mdx",".md"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OI Wiki","short_name":"OI Wiki","start_url":"/","display":"standalone","icon":"icon/favicon_512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":[],"workboxConfig":{"importWorkboxFrom":"local","globPatterns":["page-data/**","*.js"],"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkOnly"}],"skipWaiting":true,"clientsClaim":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
